import React, { useEffect } from 'react';
import './Category.css';
import { useNavigate } from 'react-router-dom';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const Category = ({ displayPictures }) => { // Destructure displayPictures from props
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector('.scroll-section');
      
      if (section) {
        const sectionPosition = section.getBoundingClientRect().top;
        const screenPosition = window.innerHeight / 1.2;

        if (sectionPosition < screenPosition) {
          section.classList.add('active');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {displayPictures && displayPictures.length > 0 && 
        <div className="scroll-section">
          <div 
            className="image image-left" 
            style={{
              backgroundImage: `url(${baseUrl}/files/${displayPictures[0].collectionId}/${displayPictures[0].id}/${displayPictures[0].bootCategory})`
            }}>
            <div className="text">
              <h2>BOOTS</h2>
              <p onClick={() => navigate('/products/boots')}>Collection</p>
            </div>
          </div>
          <div 
            className="image image-right" 
            style={{
              backgroundImage: `url(${baseUrl}/files/${displayPictures[0].collectionId}/${displayPictures[0].id}/${displayPictures[0].clothingCategory})`
            }}>
            <div className="text">
              <h2>CLOTHING</h2>
              <p onClick={() => navigate('/products/jackets')}>Collection</p>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default Category;
