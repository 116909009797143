import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';
import Navbar from '../../components/Navbar/Navbar';
import Category from '../../components/Category/Category';
import About from '../../components/About/About';
import LatestCollection from '../../components/LatestCollection/LatestCollection';
import Footer from '../../components/Footer/Footer';
import PocketBase from 'pocketbase';

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_API_URL);


const LandingPage = () => {

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const navigate = useNavigate()

  const [displayPictures, setDisplayPictures] = useState([]);
 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const records = await pb.collection('displayPictures').getFullList();
        setDisplayPictures(records);
      } catch (error) {
        // console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  

  if(!displayPictures || displayPictures.length === 0) return;

  
  return (
    <>
      { displayPictures && displayPictures.length > 0 && 
        <>
       <Navbar />
<div className="landingPage">
  {/* Video Background */}
  <video
    className="videoBackground"
    autoPlay
    loop
    muted
  >
    <source
      src={`${baseUrl}/files/${displayPictures[0].collectionId}/${displayPictures[0].id}/${displayPictures[0].landingPage}`}
      type="video/mp4"
    />
    Your browser does not support the video tag.
  </video>

  {/* Overlay Content */}
  <h1>The Class that builds your personality</h1>
  <p onClick={() => navigate('/products/boots')}>Discover Our Collection</p>
</div>
<Category displayPictures={displayPictures} />
<About displayPictures={displayPictures} />
<LatestCollection />
<Footer />

    </>
      }
    </>

  );
};

export default LandingPage;
