import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import Repair from '../../components/Repair/Repair';
import '../HelpPage/HelpPage.css';
import DownArrow from '../../assets/images/downArrowIcon.svg'; 

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_API_URL);

const ServicePage = () => {

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const location = useLocation();
  const navigate = useNavigate();

  const [showRepair, setShowRepair] = useState(true);
  const [showPersonalization, setShowPersonalization] = useState(false);
  const [showServicing, setShowServicing] = useState(false);
  const [sortModalOpen, setSortModalOpen] = useState(false);
  const [staticContents, setStaticContents] = useState();


  const toggleSortModal = () => {
    setSortModalOpen(!sortModalOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const records = await pb.collection('staticContents').getFullList();
        setStaticContents(records);
      } catch (error) {
        // console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const section = params.get('section');
    if (section === 'repair') {
      setShowRepair(true);
      setShowPersonalization(false);
      setShowServicing(false)
    } else if (section === 'personalization') {
      setShowPersonalization(true);
      setShowRepair(false);
      setShowServicing(false)
    } else if (section === 'servicing') {
      setShowServicing(true);
      setShowPersonalization(false)
      setShowRepair(false)
    }
  }, [location]);

  return (
    <>
      <Navbar />
      <div className="help-container">
        <div className="help-header">
          <h1 className="help-title">SERVICES</h1>
          <div className="help-menu">
            <div 
              style={{borderBottom: `${showRepair ? '1px solid black' : 'white'}`}} 
              onClick={() => { navigate('/service?section=repair'); }}
            >
              <p>Repair</p>
            </div>
            <div 
              style={{borderBottom: `${showPersonalization ? '1px solid black' : 'white'}`}} 
              onClick={() => { navigate('/service?section=personalization');}}
            >
              <p>Personalization</p>
            </div>
            <div 
              style={{borderBottom: `${showServicing ? '1px solid black' : 'white'}`}} 
              onClick={() => { navigate('/service?section=servicing');}}
            >
              <p>Servicing</p>
            </div>
          </div>
        </div>

        <div className="mobile-help-header">
          <h1 className="mobile-help-title">SERVICE</h1>
          <div className="mobile-help-menu">
            <div className="mobile-help-menu-title" onClick={toggleSortModal}>
              <span>Menu</span>
              <img
                src={DownArrow}
                alt="arrow"
                className={`arrow-icon ${sortModalOpen ? 'up' : 'down'}`}
              />
            </div>
            {sortModalOpen && (
              <div className="help-menu-modal">
                <p  onClick={() => { navigate('/service?section=repair'); setSortModalOpen(!sortModalOpen) }}>Repair</p>
                <p onClick={() => { navigate('/service?section=personalization'); setSortModalOpen(!sortModalOpen)  }}>Personalization</p>
                <p  onClick={() => { navigate('/service?section=servicing'); setSortModalOpen(!sortModalOpen)   }}
                >Servicing</p>

              </div>
            )}
          </div>
        </div>

        <div className="help-content">
          {showRepair && (
            <div>
              <Repair />
            </div>
          )}
          {showPersonalization && staticContents && staticContents.length > 0 && (
            <div className='product-care'>
              <h4 className='product-care-title'>Personalization</h4>
              <div dangerouslySetInnerHTML={{ __html: staticContents[0].personalization }} />

            </div>
          )}
          {
            showServicing && staticContents && staticContents.length > 0 && (
                <div className='product-care'>
                <h4 className='product-care-title'>Servicing</h4>
                <div dangerouslySetInnerHTML={{ __html: staticContents[0].servicing }} />

              </div>
            )
          }
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ServicePage;
