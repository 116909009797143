import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';
import Latest1 from '../../assets/images/latest1.png';
import Latest2 from '../../assets/images/latest2.png';
import Latest3 from '../../assets/images/latest3.png';
import './LatestCollection.css'; 


const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_API_URL);


const LatestCollection = () => {

  const navigate= useNavigate();

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const [products, setProducts] = useState();

  const images = [
    { src: Latest1, caption: 'Nexon 12XY' },
    { src: Latest2, caption: 'Nexon 13XY' },
    { src: Latest3, caption: 'Nexon 14XY' },
  ];

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const records = await pb.collection('products').getFullList({ expand: 'category'});
        setProducts(records);
      } catch (error) {
        // console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="latest-section">
      <div className="section-header">
        <h2>Latest From Le LORD</h2>
        <div className="underline"></div>
        <p onClick={() => navigate('/products/boots')}>Discover More</p>
      </div>
      {
        products && products.length > 0 && 
        <div className="image-row">
        {products.slice(0, 3).map((product, index) => (
          <div key={index} className="image-container">
            <img
              src={`${baseUrl}/files/${product.collectionId}/${product.id}/${product.coverPicture}`}
              alt={`sample ${index + 1}`}
              className="slider-image"
              style={{cursor:'pointer'}}
              onClick={() => navigate(`/product/${product.id}`)}
            />
            <p className="image-caption" onClick={() => navigate(`/product/${1}`)}
            >{product.name}</p>
          </div>
        ))}
      </div>
      }
     
    </div>
  );
};

export default LatestCollection;
