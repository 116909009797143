import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import DownArrow from '../../assets/images/downArrowIcon.svg'; 
import './HelpPage.css';

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_API_URL);

const HelpPage = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const [showFaq, setShowFaq] = useState(true);
  const [showProductCare, setShowProductCare] = useState(false);
  const [openQuestions, setOpenQuestions] = useState({});
  const [sortModalOpen, setSortModalOpen] = useState(false);

  const [faqList, setFaqList] = useState();
  const [staticContents, setStaticContents] = useState();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const records = await pb.collection('faq').getFullList();
        setFaqList(records);
      } catch (error) {
        // console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const records = await pb.collection('staticContents').getFullList();
        setStaticContents(records);
      } catch (error) {
        // console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);



  const toggleSortModal = () => {
    setSortModalOpen(!sortModalOpen);
  };

  const toggleQuestion = (index) => {
    setOpenQuestions(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const section = params.get('section');
    if (section === 'faq') {
      setShowFaq(true);
      setShowProductCare(false);
    } else if (section === 'productCare') {
      setShowFaq(false);
      setShowProductCare(true);
    }
  }, [location]);

  return (
    <>
      <Navbar />
      <div className="help-container">
        <div className="help-header">
          <h1 className="help-title">HELP</h1>
          <div className="help-menu">
            <div 
              style={{borderBottom: `${showFaq ? '1px solid black' : 'white'}`}} 
              onClick={() => { navigate('/help?section=faq'); }}
            >
              <p>FAQ</p>
            </div>
            <div 
              style={{borderBottom: `${showProductCare ? '1px solid black' : 'white'}`}} 
              onClick={() => { navigate('/help?section=productCare'); }}
            >
              <p>Product Care</p>
            </div>
          </div>
        </div>

        <div className="mobile-help-header">
          <h1 className="mobile-help-title">HELP</h1>
          <div className="mobile-help-menu">
            <div className="mobile-help-menu-title" onClick={toggleSortModal}>
              <span>Menu</span>
              <img
                src={DownArrow}
                alt="arrow"
                className={`arrow-icon ${sortModalOpen ? 'up' : 'down'}`}
              />
            </div>
            {sortModalOpen && (
              <div className="help-menu-modal">
                <p  onClick={() => { navigate('/help?section=faq'); setSortModalOpen(!sortModalOpen); }}>FAQ's</p>
                <p onClick={() => { navigate('/help?section=productCare'); setSortModalOpen(!sortModalOpen); }}>Product Care</p>
              </div>
            )}
          </div>
        </div>

        <div className="help-content">
          {showFaq && (
            <div>
              <p>FAQ</p>
              {faqList && faqList.length > 0 && 
                faqList.map((item, index) => (
                <div key={index}>
                  <div className="faq-question" onClick={() => toggleQuestion(index)}>
                    <p>{item.question}</p>
                    <img 
                      src={DownArrow} 
                      alt="down arrow" 
                      className={`arrow-icon ${openQuestions[index] ? 'up' : 'down'}`} 
                    />
                  </div>
                  <div 
                    className={`faq-answer ${openQuestions[index] ? 'open' : ''}`}
                  >
                    <p>{item.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
          {showProductCare && staticContents && staticContents.length > 0 && (
            <div className='product-care'>
              <h4 className='product-care-title'>Product Care</h4>
              <div dangerouslySetInnerHTML={{ __html: staticContents[0].productCare }} />

            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HelpPage;
