import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from '../pages/LandingPage/LandingPage';
import ProductDetailPage from '../pages/ProductDetailPage/ProductDetailPage';
import BootCollection from '../components/BootCollection/BootCollection';
import JacketCollection from '../components/JacketCollection/JacketCollection';
import HelpPage from '../pages/HelpPage/HelpPage';
import ServicePage from '../pages/ServicePage/ServicePage';
import ScrollToTop from './ScrollToTop';
import AboutPage from '../pages/AboutPage/AboutPage';
import ShoesCollection from '../components/ShoesCollection/ShoesCollection';
import PantCollection from '../components/PantCollection/PantCollection';
import ShirtCollection from '../components/ShirtCollection/ShirtCollection';
import AllProducts from '../components/SearchProducts/AllProducts';

const AppRoutes = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/product/:productId" element={<ProductDetailPage />} />
        <Route path="/products/boots" element={<BootCollection />} />
        <Route path="/products/shoes" element={<ShoesCollection />} />
        <Route path="/products/jackets" element={<JacketCollection />} />
        <Route path="/products/pants" element={<PantCollection />} />
        <Route path="/products/shirts" element={<ShirtCollection />} />
        <Route path="/help" element={<HelpPage />} />
        <Route path="/service" element={<ServicePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path='/allProducts/:searchItem?' element={<AllProducts />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;