import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Close from '../../assets/images/close.svg'; // Close icon for input field
import Search from '../../assets/images/search.svg';
import SampleImg from '../../assets/images/sampleImg.png';
import SampleImg2 from '../../assets/images/sampleImg2.png';
import './Searchbar.css';

const Searchbar = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { searchItem } = useParams(); 

  const [query, setQuery] = useState(searchItem || '');

  // Handle search action
  const handleSearch = () => {
    if (query.trim()) {
      navigate(`/allProducts/${encodeURIComponent(query.trim())}`);
      onClose(); 
    }
  };

  // Handle input clearing, redirection, and closing the search bar
  const handleClearSearch = () => {
    setQuery(''); 
    navigate('/allProducts'); 
    onClose(); 
  };

  // Detect "Enter" key press inside the input field
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  // Sync the input field when navigating to /allProducts/:searchItem
  useEffect(() => {
    if (location.pathname.includes('/allProducts') && searchItem) {
      setQuery(searchItem); 
    }
  }, [location, searchItem]);

  return (
    <div className={`search-bar ${isOpen ? 'open' : ''}`}>
      <div className="search-bar-header">
        <img
          src={Close}
          alt="close icon"
          className="close-icon"
          onClick={onClose}
        />
      </div>
      <div className="search-bar-body">
        <div className="search-input-wrapper">
          <img
            src={Search}
            alt="search icon"
            className={`search-icon ${query ? 'hidden' : ''}`}
          />
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Search..."
          />
          {query && (
            <img
              src={Close} 
              alt="clear search"
              className="input-clear-icon"
              onClick={handleClearSearch}
            />
          )}
        </div>
        <div className="search-content">
          <div className="search-column-left">
            <p onClick={() => navigate('/allProducts')}>Men</p>
            <p className='women'>Women (Coming soon)</p>
            <p onClick={() => navigate('/products/boots')}>Shoes</p>
            <p onClick={() => navigate('/products/boots')}>Boots</p>
          </div>
          <div className="search-divider"></div>
          <div className="search-column-right">
            <h3 className="product-title">PRODUCTS</h3>
            <div className="product-grid">
              <div className="product-item">
                <img src={SampleImg} alt="sample product" onClick={() => navigate('/products/boots')} style={{cursor:'pointer'}} />
                <p onClick={() => navigate('/products/boots')}>Men Boots</p>
              </div>
              <div className="product-item">
                <img src={SampleImg2} alt="sample product" onClick={() => navigate('/products/boots')} style={{cursor:'pointer'}} />
                <p onClick={() => navigate('/products/boots')}>Men Shoes</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Searchbar;
