import React, {useState, useEffect, useRef} from 'react';
import CloseIcon from '../../assets/images/close.svg'
import SubmenuSidebar from '../SubmenuSidebar/SubmenuSidebar';
import './Sidebar.css';
import { useNavigate } from 'react-router-dom';

const Sidebar = ({ isOpen, onClose }) => {

    const navigate = useNavigate()

    const sidebarRef = useRef(null);

    const [submenuOpen, setSubmenuOpen] = useState(false);

    const handleSubmenuToggle = () => {
        setSubmenuOpen(!submenuOpen);
      };
    
    const handleCloseSubmenu = () => {
        setSubmenuOpen(!submenuOpen);
      };

    const handleClickOutside = (e) => {
        if (sidebarRef.current && !sidebarRef.current.contains(e.target)) {
          onClose();
        }
    };

    useEffect(() => {
      if (isOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isOpen]);

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`} ref={sidebarRef}>
      <div className="sidebar-header">
        <img src={CloseIcon} className="close-icon" alt='Close' onClick={onClose} />
      </div>
      <ul className="sidebar-menu">
        <li className="menu-item" onClick={() => navigate('/')}>Home</li>
        <li className="menu-item with-submenu">
          Men
          <ul className="submenu">
            <li className='men-submenu-item' onClick={() => navigate('/products/boots')}>Boots</li>
            <li className='men-submenu-item' onClick={() => navigate('/products/shoes')} >Shoes</li>
            <li className='men-submenu-item' onClick={handleSubmenuToggle}>Clothing</li>
          </ul>
        </li>
        <li className="menu-item">Women</li>
        <li className="menu-item" onClick={() => navigate('/allProducts')}>All Products</li>
      </ul>
      <SubmenuSidebar isOpen={submenuOpen} onClose={handleCloseSubmenu} />
    </div>
  );
};

export default Sidebar;
