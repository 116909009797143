import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import DownArrow from '../../assets/images/downArrowIcon.svg';
import '../BootCollection/BootCollection.css';

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_API_URL);

const BootCollection = () => {
  const navigate = useNavigate();
  const [sortModalOpen, setSortModalOpen] = useState(false);
  const [bootsCollection, setBootCollection] = useState([]);

  const toggleSortModal = () => {
    setSortModalOpen(!sortModalOpen);
  };

  const handleAscSort = () => {
    const fetchSortedData = async () => {
      try {
        const records = await pb.collection('products').getFullList({
          expand: 'category',
          filter: `category.title = 'clothing'`,
          sort: 'price',
        });
        setBootCollection(records);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchSortedData();
  };

  const handleDescSort = () => {
    const fetchSortedData = async () => {
      try {
        const records = await pb.collection('products').getFullList({
          expand: 'category',
          filter: `category.title = 'clothing'`,
          sort: '-price',
        });
        setBootCollection(records);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchSortedData();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const records = await pb.collection('products').getFullList({
          expand: 'category',
          filter: `category.title = 'clothing'`,
        });
        setBootCollection(records);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Navbar />
      <div className="product-gallery">
        <div className="product-header">
          <h1 className="title">Jackets</h1>
          <div className="sort-bar">
            <div className="sort-title" onClick={toggleSortModal}>
              <span>Sort by</span>
              <img
                src={DownArrow}
                alt="arrow"
                className={`arrow-icon ${sortModalOpen ? 'up' : 'down'}`}
              />
            </div>
            {sortModalOpen && (
              <div className="sort-modal">
                <p onClick={() => { toggleSortModal(); handleDescSort(); }}>
                  Price High to Low
                </p>
                <p onClick={() => { toggleSortModal(); handleAscSort(); }}>
                  Price Low to High
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="gallery">
          {bootsCollection && bootsCollection.length > 0 &&
            bootsCollection.map((boot) => (
              <div key={boot.id} onClick={() => navigate(`/product/${boot.id}`)}>
                <ImageSlider boot={boot} />
                <p style={{fontWeight:'600', textTransform:'uppercase'}}>{boot.name}</p>
              </div>
            ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

const ImageSlider = ({ boot }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? boot.images.length - 1 : prevIndex - 1));
  };

  const handleNext = (e) => {
    e.stopPropagation();
    setCurrentIndex((prevIndex) => (prevIndex === boot.images.length - 1 ? 0 : prevIndex + 1));
  };

  if (!boot.images || boot.images.length === 0) {
    return <div>No images available</div>;
  }

  // Construct image URLs using the base URL
  const baseUrl = process.env.REACT_APP_API_BASE_URL; // Replace with your API base URL if necessary
  const imageUrls = boot.images.map((image) => `${baseUrl}/files/${boot.collectionId}/${boot.id}/${image}`);

  return (
    <div className="image-slider">
      <button className="slider-btn left" onClick={handlePrev}></button>
      <img src={imageUrls[currentIndex]} alt={`Product ${currentIndex + 1}`}  />
      <button className="slider-btn right" onClick={handleNext}></button>
    </div>
  );
};

export default BootCollection;
