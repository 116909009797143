import React from 'react';
import './About.css'; 

const About = ({displayPictures}) => {

  const baseUrl = process.env.REACT_APP_API_BASE_URL;


  return (
    <>
    
      <div className="about-section">
        <div className="about-content">
          <div className="about-image">
          {
            displayPictures && displayPictures.length > 0 && 
            <img src={`${baseUrl}/files/${displayPictures[0].collectionId}/${displayPictures[0].id}/${displayPictures[0].aboutLelord}`} alt="About" />
          }
            </div>
          <div className="about-text">
            <h2>The Style We Believe In</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eu lacus vel neque
              rhoncus tempor. Sed sed est ut nisl condimentum rhoncus ac vel lectus. Sed sed est ut nisl condimentum rhoncus ac vel lectus.
            </p>
            <span>With love and grace, LE LORD</span>
          </div>
        </div>
      </div>
    
    </>
  );
};

export default About;
