import React, {useRef, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import SampleImg3 from '../../assets/images/sampleImg3.png'
import SampleImg4 from '../../assets/images/sampleImg4.png'

import './SubmenuSidebar.css'; 

const SubmenuSidebar = ({ isOpen, onClose }) => {

    const navigate = useNavigate();
    const submenuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (isOpen && submenuRef.current && !submenuRef.current.contains(event.target)) {
            onClose(); 
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [isOpen, onClose]);

  return (
    <div className={`submenu-sidebar ${isOpen ? 'open' : ''}`} ref={submenuRef}>
    <div className="submenu-sidebar-content">
      <div className="submenu-row">
        <div className="submenu-column">
          <img src={SampleImg3} alt="smaple item" />
        </div>
        <div className="submenu-column">
          <img src={SampleImg4} alt="sample item" />
        </div>
      </div>
     
      <div className="submenu-row">
        <ul className="submenu-list">
          <li className='nested-menu' onClick={() => navigate('/products/jackets')}>Jackets</li>
          <li className='nested-menu' onClick={() => navigate('/products/pants')}>Pants</li>
          <li className='nested-menu' onClick={() => navigate('/products/shirts')}>Shirts</li>
        </ul>
      </div>
    </div>
  </div>
  );
};

export default SubmenuSidebar;
