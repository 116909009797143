import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import DownArrow from '../../assets/images/downArrowIcon.svg'; 
import '../BootCollection/BootCollection.css';

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_API_URL);

const ShoesCollection = () => {

  const navigate = useNavigate()
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const [sortModalOpen, setSortModalOpen] = useState(false);
  const [bootsCollection, setBootCollection] = useState([]);


  const toggleSortModal = () => {
    setSortModalOpen(!sortModalOpen);
  };

  const handleAscSort = () => {
    const fetchSortedData = async () => {
      try {
        const records = await pb.collection('products').getFullList({
          expand: 'category',
          filter: `category.title = 'shoes'`,
          sort: 'price',
      });
      setBootCollection(records);
      } catch (error) {
        // console.error('Error fetching data:', error);
      }
    };

    fetchSortedData();
  }

  const handleDescSort = () => {
    const fetchSortedData = async () => {
      try {
        const records = await pb.collection('products').getFullList({
          expand: 'category', // Expand the 'category' relation
          filter: `category.title = 'shoes'`,
          sort: '-price',
      });
      setBootCollection(records);
      } catch (error) {
        // console.error('Error fetching data:', error);
      }
    };

    fetchSortedData();
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const records = await pb.collection('products').getFullList({
          expand: 'category',
          filter: `category.title = 'shoes'`
      });
      setBootCollection(records);
      } catch (error) {
        // console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  return (
    <>
    <Navbar />
    <div className="product-gallery">
      <div className="product-header">
        <h1 className="title">Shoes</h1>
        <div className="sort-bar">
          <div className="sort-title" onClick={toggleSortModal}>
            <span>Sort by</span>
            <img
              src={DownArrow}
              alt="arrow"
              className={`arrow-icon ${sortModalOpen ? 'up' : 'down'}`}
            />
          </div>
          {sortModalOpen && (
            <div className="sort-modal">
              <p onClick={() => {toggleSortModal(); handleDescSort();}}>Price High to Low</p>
              <p onClick={() => {toggleSortModal(); handleAscSort();}}>Price Low to High</p>
            </div>
          )}
        </div>
      </div>
      <div className="gallery">
        {
          bootsCollection && bootsCollection.length > 0 && 
            bootsCollection.map((boot) => (
              <div onClick={() => navigate(`/product/${boot.id}`)}>
              <img src={`${baseUrl}/files/${boot.collectionId}/${boot.id}/${boot.coverPicture}`} alt="shoes" />
              <p>{boot.name}</p>
            </div>
            ))
        }
        <p className='no-product-msg'>Produtcs Coming Soon..</p>

      </div>
    </div>
    <Footer />
    </>
  );
};

export default ShoesCollection;
