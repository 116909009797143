// ProductDetailPage.js
import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import PocketBase from 'pocketbase';
import './ProductDetailPage.css';
import LatestCollection from '../../components/LatestCollection/LatestCollection';
import SizeChart from '../../assets/images/sizeChart.png';
import PlusIcon from '../../assets/images/addIcon.svg';
import MinusIcon from '../../assets/images/minusIcon.svg';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import CallIcon from '../../assets/images/callIcon.svg';
import InstaIcon from '../../assets/images/insta.svg';
import CloseIcon from '../../assets/images/close.svg';

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_API_URL);


const ProductDetailPage = () => {

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const { productId } = useParams();

  const modalRef = useRef(null);



  const [openCallForEnquiryModal, setOpenCallForEnquiryModal] = useState(false);
  const [, setSelectedSize] = useState('M');
  const [modalOpen, setModalOpen] = useState(false);
  const [sizeChartModalOpen, setSizeChartModalOpen] = useState(false);
  const [productDetailModalOpen, setProductDetailModalOpen] = useState(false);
  const [sustainabilityModalOpen, setSustainabilityModalOpen] = useState(false);
  const [productCareModalOpen, setProductCareModalOpen] = useState(false);
  const [productDetail, setProductDetail] = useState()
  const leftSectionRef = useRef(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false)
  const [error, setError] = useState('')

  const [formData, setFormData] = useState({
    name: '',
    contact_number:'',
    email: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
        ...formData,
        [name]: value,
    });

      // Clear error for contact_number when it becomes valid
      if (name === 'contact_number') {
        const contactNumberRegex = /^\d{9}$/;
        if (contactNumberRegex.test(value)) {
          setError('');
        }
      }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation: Check if contact number is exactly 9 digits
    const contactNumberRegex = /^\d{8,12}$/;
    if (!contactNumberRegex.test(formData.contact_number)) {
      setError('Contact number must be between 8 to 12 digits.');
      return;
    }

    // Validation: Ensure all fields are filled
    if (!formData.name || !formData.email || !formData.contact_number) {
      setError('Please fill in all fields before submitting.');
      return;
    }

    try {
      // Submit form data to the server
      await pb.collection('customers').create(formData);
      setSuccessMsg(true);
      setError('');

      // Reset form fields after successful submission
      setFormData({
        name: '',
        contact_number:'',
        email: '', 
      });

      // Hide success message after 3 seconds
      setTimeout(() => {
        setSuccessMsg(false);
      }, 3000);
    } catch (error) {
      console.error('Error submitting form:', error);
      setError('Failed to submit the form. Please try again.');
    }
  };


  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setContactModalOpen(false);
      setFormData({
        name: '',
        contact_number:'',
        email: '', 
      });
      setError('')
    }
  };


  
  useEffect(() => {
    if (contactModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [contactModalOpen]);




  useEffect(() => {
    const fetchData = async () => {
      try {
        const record = await pb.collection('products').getOne(productId, {expand: 'availableSizes'});
        setProductDetail(record);       

      } catch (error) {
        // console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [productId]);

  console.log(productDetail)

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const handleSizeChartToggle = () => {
    setSizeChartModalOpen(!sizeChartModalOpen);
  };

  const handleProductDetailToggle = () => {
    setProductDetailModalOpen(!productDetailModalOpen);
  };

  const handleSustainabilityToggle = () => {
    setSustainabilityModalOpen(!sustainabilityModalOpen);
  };

  const handleProductCareToggle = () => {
    setProductCareModalOpen(!productCareModalOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (!leftSectionRef.current) return;

      const scrollTop = leftSectionRef.current.scrollTop;
      const totalHeight = leftSectionRef.current.scrollHeight - leftSectionRef.current.clientHeight;
      const activeSlide = Math.round((scrollTop / totalHeight) * (productDetail.images.length - 1));

      document.querySelectorAll('.carousel .slide').forEach((slide, index) => {
        slide.style.opacity = index === activeSlide ? '1' : '0';
      });
    };

    const leftSection = leftSectionRef.current;
    if (leftSection) {
      leftSection.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (leftSection) {
        leftSection.removeEventListener('scroll', handleScroll);
      }
    };
  }, [productDetail]);

 
  return (
    <>
    {productDetail && 
      <>
      <Navbar />
        <div className="product-detail-page">
          <div className="left-section" ref={leftSectionRef}>
            {productDetail.images.map((image, index) => (
              <div key={index} className="carousel slide">
                <img src={`${baseUrl}/files/${productDetail.collectionId}/${productDetail.id}/${image}`} alt={`Product ${index + 1}`} className="slide-img" />
              </div>
            ))}
          </div>
          <div className="right-section">
            <div className='product-detail-header'>
              <h1 className="product-title">{productDetail.name}</h1>
              <p className='product-id'>{productDetail.code}</p>
            </div>
            <div className="product-color-container">
              <span>Color </span>
              <div className='product-color'>
                  <div className='color-notation'>
                    {productDetail.color}
                    <div
                      className={`color-option`}
                      style={{backgroundColor:productDetail.color}}
                    ></div>
                  </div>
              </div>
            </div>
            <div className="product-size">
              <div className="size-dropdown" onClick={handleModalToggle}>
                <span>Size</span>
                <span className={`arrow ${modalOpen ? 'up' : 'down'}`}>
                  <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_391_42)">
                      <path d="M13.0648 2.33899L13.8598 3.13474L9.52707 7.46899C9.45765 7.53886 9.37509 7.5943 9.28415 7.63214C9.19322 7.66997 9.09569 7.68945 8.9972 7.68945C8.8987 7.68945 8.80118 7.66997 8.71024 7.63214C8.61931 7.5943 8.53675 7.53886 8.46732 7.46899L4.13232 3.13474L4.92732 2.33974L8.99607 6.40774L13.0648 2.33899Z" fill="#111111" />
                    </g>
                    <defs>
                      <clipPath id="clip0_391_42">
                        <rect width="9" height="18" fill="white" transform="matrix(0 1 -1 0 18 0.5)" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </div>
                <div className={`size-modal ${modalOpen ? 'open' : ''}`}>
                  {productDetail.expand.availableSizes.map((size, index) => (
                    <div
                      key={index}
                      className="size-option"
                      onClick={() => {
                        setSelectedSize(size);
                        setModalOpen(false);
                      }}
                    >{size.sizeNumber}</div>
                  ))}
            

              </div>

              {modalOpen &&
                  <p className='size-chart-text' onClick={handleSizeChartToggle}>Size Chart</p>

              }

              
            </div>
            <div className="product-button">
              <button onClick={() => setOpenCallForEnquiryModal(!openCallForEnquiryModal)}>Call for Enquiry</button>
            </div>
            

            { openCallForEnquiryModal && 
               <div className='call-for-enquiry-modal'>
                <div style={{display:'flex', alignItems:'center', gap: 10}}>
                  <img src={CallIcon} alt='call icon' style={{height: 20, widht: 20}}></img>
                  <p style={{margin:0, cursor:'pointer'}}>+977 9833455345</p>
                </div>
                <div style={{display:'flex', alignItems:'center', gap: 10}}>
                <img src={InstaIcon} alt='insta icon' style={{height: 20,widht: 20, margin:0}}></img>
                  <p style={{margin:0, cursor:'pointer'}}><Link to='https://instagram.com/lelord.co'  target='_blank' style={{textDecoration:'none', color:'black'}}>Chat on Instagram</Link></p>
                </div>
                <div style={{display:'flex', alignItems:'center', gap: 10}}>
                  {/* <img src={CallIcon} alt='call icon' style={{height: 20, widht: 20}}></img> */}
                  <p style={{margin:0, cursor:'pointer', textDecoration:'underline'}} onClick={() => setContactModalOpen(true)}>Leave Your Contact</p>
                </div>
              </div>
            }

            {contactModalOpen && (
                  <div className="modal-overlay">
                    <div className="modal-content" ref={modalRef}>
                    <img src={CloseIcon} className="close-button"
                         onClick={() => {
                          setContactModalOpen(false);
                          setFormData({
                            name: '',
                            contact_number:'',
                            email: '', 
                          });
                          setError('')}} 
                    />
                   
                    <h2>Stay in touch!</h2>
                    <div className="customer-contact-form" style={{width:'100%'}}>
                        <form onSubmit={handleSubmit}>
                          <label>
                            Customer Name
                            <input
                              type="text"
                              name="name"
                              value={formData.customer_name}
                              onChange={handleInputChange}
                              required
                            />
                          </label>
                        
                          <label>
                            Email
                            <input
                              type="email"
                              name="email"
                              value={formData.email}
                              onChange={handleInputChange}
                              required
                            />
                          </label>
                          <label>
                            Contact Number
                            <input
                              type="text"
                              name="contact_number"
                              value={formData.contact_number}
                              onChange={handleInputChange}
                              required
                            />
                          </label>
                        
                          {error && <p style={{ color: 'red', fontSize:'12px', fontWeight:'200' }}>{error}</p>}
                          <button className="form-submit-btn" type="submit">Submit</button>
                        </form>
                      {successMsg && <div className="success-toast">Thank you. We will reach out soon.</div>}
                    </div>
                  </div>
                </div>
            )}
           
          
            <div className="product-detail-modal">
              <p onClick={handleProductDetailToggle}>Product Details</p>
            </div>
          </div>
        </div>
        <LatestCollection />
        {sizeChartModalOpen && (
          <div className="size-chart-modal">
            <div className="size-chart-content">
              <span className="size-chart-close-button" onClick={handleSizeChartToggle}>
                <svg width="16" height="17" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.01015 19L0 17.9337L7.98985 9.5L0 1.06627L1.01015 0L9 8.43373L16.9899 0L18 1.06627L10.0101 9.5L18 17.9337L16.9899 19L9 10.5663L1.01015 19Z" fill="#313131"/>
                </svg>
              </span>
              <h5>LE LORD's Men's Size Chart</h5>
              <img src={SizeChart} alt="Size Chart" />
            </div>
          </div>
        )}
        <div className={`product-detail-modal-content ${productDetailModalOpen ? 'open' : ''}`}>
          <span className="close-button" onClick={handleProductDetailToggle}>
            <svg  width="14" height="15" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.01015 19L0 17.9337L7.98985 9.5L0 1.06627L1.01015 0L9 8.43373L16.9899 0L18 1.06627L10.0101 9.5L18 17.9337L16.9899 19L9 10.5663L1.01015 19Z" fill="#313131"/>
            </svg>
          </span>
          <div className="product-detail-modal-body">
            <h4>Product Detail</h4>
            <div className='product-brief'>
            <div dangerouslySetInnerHTML={{ __html: productDetail.description }} />
            </div>
            <div className='sustainability'>
                  <p>Sustainability</p>
                  <p onClick={handleSustainabilityToggle}>
                  <img src={sustainabilityModalOpen ? MinusIcon : PlusIcon} alt="Toggle Icon" />
                  </p>

            </div>
            <div className={`sustainability-modal ${sustainabilityModalOpen ? 'open' : ''}`}>

            <div dangerouslySetInnerHTML={{ __html: productDetail.sustainability }} />

                {/* <p>

                The leather used in this product comes from a tannery audited and  certified by the Leather Working Group (LWG),
                which is the highest  environmental standard in terms of leather tanning. This standard  requires 
                tanneries to reduce their water and energy consumption, as well  as their use of potentially hazardous 
                substances. The tanneries we work  with are located in Europe and are committed partners who work with us  
                in our efforts for responsible sourcing and continuous improvement  (implementation of material traceability 
                systems, fighting against  deforestation practices).

                </p> */}
              </div>
            <div className='sustainability'>
                  <p>Product Care</p>
                  <p onClick={handleProductCareToggle}>
                  <img src={productCareModalOpen ? MinusIcon : PlusIcon} alt="Toggle Icon" />

                </p>

            </div>
            <div className={`sustainability-modal ${productCareModalOpen ? 'open' : ''}`}>
            <div dangerouslySetInnerHTML={{ __html: productDetail.productCare }} />

                {/* <p>

                The leather used in this product comes from a tannery audited and  certified by the Leather Working Group (LWG),
                which is the highest  environmental standard in terms of leather tanning. This standard  requires 
                tanneries to reduce their water and energy consumption, as well  as their use of potentially hazardous 
                substances. The tanneries we work  with are located in Europe and are committed partners who work with us  
                in our efforts for responsible sourcing and continuous improvement  (implementation of material traceability 
                systems, fighting against  deforestation practices).

                </p> */}
              </div>
          </div>
        </div>
        <Footer />
      </>
    }
    </>
  );
};

export default ProductDetailPage;
