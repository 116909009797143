import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import CoverImage from '../../assets/images/coverImage.png';
import '../HelpPage/HelpPage.css';
import './AboutPage.css'
import Testimonial from '../../components/Testimonial/Testimonial';
import DownArrow from '../../assets/images/downArrowIcon.svg'; 


const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_API_URL);


const AboutPage = () => {

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const location = useLocation();
  const navigate = useNavigate();

  const [showLelord, setShowLelord] = useState(true);
  const [showTestimonials, setShowTestimonials] = useState(false);
  const [showServicing,] = useState(false);
  const [sortModalOpen, setSortModalOpen] = useState(false);
  const [staticContents, setStaticContents] = useState([]);



  const toggleSortModal = () => {
    setSortModalOpen(!sortModalOpen);
  }; 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const records = await pb.collection('staticContents').getFullList();
        setStaticContents(records);
      } catch (error) {
        // console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  
  
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const section = params.get('section');
    if (section === 'lelord') {
      setShowLelord(true);
      setShowTestimonials(false);
    } else if (section === 'testimonial') {
      setShowTestimonials(true);
      setShowLelord(false);
    }
   }, [location]);


  return (
    <>
      <Navbar />
      <div className="help-container">
        <div className="help-header">
          <h1 className="help-title">ABOUT</h1>
          <div className="help-menu">
            <div 
              style={{borderBottom: `${showLelord ? '1px solid black' : 'white'}`}} 
              onClick={() => { navigate('/about?section=lelord'); }}
            >
              <p>LE LORD</p>
            </div>
            <div 
              style={{borderBottom: `${showTestimonials ? '1px solid black' : 'white'}`}} 
              onClick={() => { navigate('/about?section=testimonial'); }}
            >
              <p>Testimonials</p>
            </div>
            {/* <div 
              style={{borderBottom: `${showServicing ? '1px solid black' : 'white'}`}} 
              onClick={() => { setShowServicing(true); setShowRepair(false); setShowPersonalization(false)}}
            >
              <p>Servicing</p>
            </div> */}
          </div>
        </div>

        <div className="mobile-help-header">
          <h1 className="mobile-help-title">ABOUT</h1>
          <div className="mobile-help-menu">
            <div className="mobile-help-menu-title" onClick={toggleSortModal}>
              <span>Menu</span>
              <img
                src={DownArrow}
                alt="arrow"
                className={`arrow-icon ${sortModalOpen ? 'up' : 'down'}`}
              />
            </div>
            {sortModalOpen && (
              <div className="help-menu-modal">
                <p  onClick={() => { navigate('/about?section=lelord'); setSortModalOpen(!sortModalOpen) }}>LE LORD</p>
                <p onClick={() => { navigate('/about?section=testimonial'); setSortModalOpen(!sortModalOpen) }}>Testimonials</p>

              </div>
            )}
          </div>
        </div>
      
        <div className="help-content">
          {showLelord && staticContents && staticContents.length > 0 &&  (
            <div className='about-lelord'>
                <img src={`${baseUrl}/files/${staticContents[0].collectionId}/${staticContents[0].id}/${staticContents[0].aboutLelordCoverPicture}`} alt='cover' />
                <div dangerouslySetInnerHTML={{ __html: staticContents[0].aboutLelord }} />
            </div>
          )}
          {showTestimonials && (
            <div className='product-care'>
                <Testimonial />
              
            </div>
          )}
          {/* {
            showServicing && (
                <div className='product-care'>
                <h4 className='product-care-title'>Servicing</h4>
                <p>Lorem ipsum dolor sit amet consectetur. Tempor volutpat nisi faucibus sed risus adipiscing ut facilisi sit. Lectus dignissim tincidunt eget tortor tincidunt ut habitant. Tellus mattis dui id volutpat. Vitae potenti morbi dignissim nunc odio nunc venenatis. Faucibus pharetra diam viverra nulla mauris et viverra blandit vestibulum.
                  <br /> <br />Morbi tortor vestibulum dui velit tristique blandit velit odio. Molestie quam quis quis eu mattis aenean velit ac nibh. Odio sit at hendrerit faucibus. Vitae nisl posuere leo vitae in auctor eget. Neque adipiscing id integer nec ornare. Suspendisse rutrum ultrices libero sagittis tincidunt ipsum cras tincidunt sit. In ultricies est scelerisque morbi facilisis velit arcu habitant. Blandit ultrices amet ac ut tellus turpis dignissim. Phasellus et adipiscing iaculis urna ornare lectus eu ornare aliquet. Nulla dolor orci consectetur fusce non maecenas.
                      Lorem ipsum dolor sit amet consectetur. Tempor volutpat nisi faucibus sed risus adipiscing ut facilisi sit. Lectus dignissim tincidunt eget tortor tincidunt ut habitant. Tellus mattis dui id volutpat. Vitae potenti morbi dignissim nunc odio nunc venenatis. Faucibus pharetra diam viverra nulla mauris et viverra blandit vestibulum.</p>
              </div>
            )
          } */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutPage;
