import AppRoutes from "./config/AppRoutes";

function App() {
  return (
    <div className="App" >
      <AppRoutes /> 
    </div>
  );
}

export default App;
