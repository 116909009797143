import React, { useState } from 'react';
import PocketBase from 'pocketbase';
import './Repair.css';
import CustomSelectField from '../CustomSelectField/CustomSelectField';

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_API_URL);

const Repair = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const [successMsg, setSuccessMsg] = useState(false);
  const [error, setError] = useState('');

  const [formData, setFormData] = useState({
    customer_name: '',
    product_name: '',
    email: '',
    contact_number: '',
    repair_description: '',
    photos: [],
  });

  const handleChange = (option) => {
    setFormData({
      ...formData,
      selectedOption: option.value,
    });
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'photos') {
      setFormData({
        ...formData,
        photos: [...formData.photos, ...Array.from(files)],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });

      // Clear error for contact_number when it becomes valid
      if (name === 'contact_number') {
        const contactNumberRegex = /^\d{9}$/;
        if (contactNumberRegex.test(value)) {
          setError('');
        }
      }
    }
  };

  const handleRemovePhoto = (index) => {
    setFormData({
      ...formData,
      photos: formData.photos.filter((_, i) => i !== index),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation: Check if contact number is exactly 9 digits
    const contactNumberRegex = /^\d{8,12}$/;
    if (!contactNumberRegex.test(formData.contact_number)) {
      setError('Contact number must be between 8 to 12 digits.');
      return;
    }

    // Validation: Ensure all fields are filled
    if (!formData.customer_name || !formData.email || !formData.contact_number || !formData.product_name || !formData.repair_description || !formData.photos.length) {
      setError('Please fill in all fields before submitting.');
      return;
    }

    try {
      // Submit form data to the server
      await pb.collection('repair_form').create(formData);
      setSuccessMsg(true);
      setError('');

      // Reset form fields after successful submission
      setFormData({
        customer_name: '',
        product_name: '',
        email: '',
        contact_number: '',
        repair_description: '',
        photos: [],
      });

      // Hide success message after 3 seconds
      setTimeout(() => {
        setSuccessMsg(false);
      }, 3000);
    } catch (error) {
      console.error('Error submitting form:', error);
      setError('Failed to submit the form. Please try again.');
    }
  };

  const options = [
    { label: 'Shoes', value: 'shoes' },
    { label: 'Boot', value: 'boot' },
    { label: 'Jacket', value: 'jacket' },
    { label: 'Shirt', value: 'shirt' },
    { label: 'Pant', value: 'pant' },
  ];

  return (
    <>
      <div className="repair-container">
        <div className="repair-form-container">
          <h2>Please fill out the following form for repairment.</h2>
          <form onSubmit={handleSubmit}>
            <label>
              Customer Name
              <input
                type="text"
                name="customer_name"
                value={formData.customer_name}
                onChange={handleInputChange}
                required
              />
            </label>

            <label>
              Product Name
              <input
                type="text"
                name="product_name"
                value={formData.product_name}
                onChange={handleInputChange}
                required
              />
            </label>

            <CustomSelectField
              options={options}
              placeholder="Product Type"
              onChange={handleChange}
            />

            <label>
              Email
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Contact Number
              <input
                type="text"
                name="contact_number"
                value={formData.contact_number}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Repair Description
              <textarea
                name="repair_description"
                value={formData.repair_description}
                onChange={handleInputChange}
                required
              />
            </label>
            <label className="file-upload">
              <input
                type="file"
                name="photos"
                onChange={handleInputChange}
                multiple
                accept="image/*"
                required
              />
            </label>
            {formData.photos.length > 0 && (
              <div className="uploaded-images">
                {formData.photos.map((photo, index) => (
                  <div className="uploaded-image" key={index}>
                    <img src={URL.createObjectURL(photo)} alt={`Uploaded ${index}`} />
                    <div className="close-icon" onClick={() => handleRemovePhoto(index)}>
                      <svg width="15" height="16" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.01015 19L0 17.9337L7.98985 9.5L0 1.06627L1.01015 0L9 8.43373L16.9899 0L18 1.06627L10.0101 9.5L18 17.9337L16.9899 19L9 10.5663L1.01015 19Z" fill="#ffffff" />
                      </svg>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <button className="form-submit-btn" type="submit">Submit</button>
          </form>
          {successMsg && <div className="success-toast">Form submitted successfully!</div>}
        </div>
        <div className="description-container">
          <h2>Repair Service Description</h2>
          <p>
            We provide a comprehensive repair service for all our products.
            Whether it's a minor fix or a major repair, our team of experts
            is here to help. Please fill out the form with the necessary
            details and upload a photo of the product that needs repair.
            Our team will review your request and get back to you as soon as
            possible with further instructions.
          </p>
        </div>
      </div>
    </>
  );
};

export default Repair;
