import React, { useState, useEffect, useRef } from 'react';
import './CustomSelectField.css';

const CustomSelectField = ({ options, onChange, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const selectRef = useRef();

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onChange(option);
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="custom-select" ref={selectRef}>
        <label>{placeholder}</label>
      <div
        className={`custom-select__trigger ${isOpen ? 'open' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
                {selectedOption ? selectedOption.label : 'Select'}
        <span className="arrow">&#9662;</span>
      </div>
      {isOpen && (
        <div className="custom-select__options">
          {options.map((option, index) => (
            <div
              key={index}
              className="custom-select__option"
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelectField;
